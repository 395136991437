import {DateTime} from "luxon";

/* @ngInject */
export default class InboxController {
  constructor($scope, $window, $mdDialog, $injector) {
    this._$scope = $scope;
    this._$rootScope = $injector.get("$rootScope");
    this._$window = $window;
    this._$mdDialog = $mdDialog;
    this._Authentication = $injector.get("Authentication");
    this._ScrollService = $injector.get("ScrollService");
    this._Search = $injector.get("SearchService");
    this._InboxItemService = $injector.get("InboxItemService");
    this._InboxItemSearchParamsService = $injector.get("InboxItemSearchParamsService");
    this._Session = $injector.get("Session");
    this.features = $injector.get("Config").features;

    this._Search.autoFillValues = [
      "patient:",
      "physician-name:",
      "device:",
      "study:",
      "study-type:",
      "number:",
      "type:",
      "age:",
      "classification:",
      "facility:",
      "recording:",
      "is:event",
      "is:notification",
      "is:report",
      "is:unconfigured",
      "is:classified",
      ...(this.features.algorithmClassification ? ["is:auto-classified"] : []),
      "is:from-finalized-study",
      "before:",
      "after:",
    ];
    this._Search.searchableColumnFields = [
      "$enrollment.study.studyDetails.patientName$",
      "$enrollment.tzSerial$",
      "type",
      "$enrollment.study.id$",
    ];
    this._Search.customStandardSearch = null;
    this._Search.typeGroups = {
      notification: [
        "study action failed",
        "unconfigured device on patient",
        "lead disconnected",
        "published report rejected",
        "high event load detected",
      ],
      event: [
        "tachycardia",
        "bradycardia",
        "unreadable ecg data",
        "normal sinus rhythm",
        "cardiac pause",
        "atrial fibrillation",
        "baseline",
        "ecg data request",
        "patient activated event",
        "bradycardia rate decrease",
        "tachycardia rate increase",
      ],
      report: ["daily trend", "summary"],
      unconfigured: (isNegated, searchObject, appendToSearch) => {
        if (appendToSearch) {
          const pendingSettingsDownload = isNegated ? "false" : "true";
          searchObject.$and.push({pendingSettingsDownload});
          return undefined;
        }
        const isFlagged = !!searchObject.pendingSettingsDownload;
        return isNegated ? !isFlagged : isFlagged;
      },
      classified: (isNegated, searchObject, appendToSearch) => {
        if (appendToSearch) {
          this._Search.searchByCategory("type", "event", false, searchObject);
          const dynamicKey = isNegated ? "$eq" : "$not";
          searchObject.$and.push({"$ecgEvent.userClassification$": {[dynamicKey]: "null"}});
          return undefined;
        }
        const hasUserClassification = searchObject.userClassification !== null;
        return isNegated ? !hasUserClassification : hasUserClassification;
      },
      unclassified: (isNegated, searchObject, appendToSearch) => {
        return this._Search.typeGroups.classified(!isNegated, searchObject, appendToSearch);
      },
      "from-finalized-study": (isNegated, searchObject, appendToSearch) => {
        if (appendToSearch) {
          const dynamicKey = isNegated ? "$not" : "$eq";
          searchObject.$and.push({"$enrollment.study.studyState$": {[dynamicKey]: "finalized"}});
          return undefined;
        }
        const belongsToFinalizedStudy = searchObject.study?.studyState === "finalized";
        return isNegated ? !belongsToFinalizedStudy : belongsToFinalizedStudy;
      },
      ...(this.features.algorithmClassification && {
        "auto-classified": (isNegated, searchObject, appendToSearch) => {
          if (appendToSearch) {
            const autoClassified = isNegated ? "false" : "true";
            searchObject.autoClassified = autoClassified;
            return undefined;
          }
          const isAutoClassified = !!searchObject.autoClassified;
          return isNegated ? !isAutoClassified : isAutoClassified;
        },
      }),
    };

    this._Search.searchByCriteria = {
      is: {criteria: "category", columnName: "type", throwIfNegated: false},
      patient: {
        criteria: "custom",
        handler: (value, isUuid, isNegated, searchObject, appendToSearch) => {
          const self = this;

          const patientSearchList = [
            {key: "$enrollment.study.studyDetails.patientName$", value},
            {key: "$enrollment.study.studyDetails.patientId$", value},
          ];

          return self._Search.searchByKeyValueList(
            patientSearchList,
            isNegated,
            false,
            searchObject,
            appendToSearch
          );
        },
      },
      "physician-name": {
        criteria: "key",
        columnName: "$enrollment.study.studyDetails.physicianName$",
        throwIfNegated: false,
        isExact: false,
      },
      device: {
        criteria: "key",
        columnName: "$enrollment.tzSerial$",
        throwIfNegated: false,
        isExact: false,
      },
      serial: {
        criteria: "key",
        columnName: "$enrollment.tzSerial$",
        throwIfNegated: false,
        isExact: false,
      },
      number: {
        criteria: "key",
        columnName: "itemNumber",
        throwIfNegated: false,
        isExact: false,
      },
      type: {
        criteria: "key",
        columnName: "type",
        throwIfNegated: false,
        isExact: false,
      },
      age: {
        criteria: "custom",
        handler: (value, isUuid, isNegated, searchObject, appendToSearch) => {
          const inputMatcher = /^(?<comparisonOperator><|>)((?<ageInDays>\d+)d)?((?<ageInHours>\d+)h)?$/;
          const matches = value.match(inputMatcher);
          if (isNegated) {
            throw new Error(`Negation not supported on search term "age".`);
          }
          if (matches === null) {
            throw new Error('Use search helper to search by "age"');
          }

          const {comparisonOperator, ageInDays = 0, ageInHours = 0} = matches.groups;
          if (ageInDays === 0 && ageInHours === 0) {
            throw new Error('Use search helper to search by "age"');
          }

          const currentTime = DateTime.utc();
          const ageTimestamp = currentTime
            .minus({days: Number(ageInDays), hours: Number(ageInHours)})
            .toISO();

          if (appendToSearch) {
            // comparisonOperator is guaranteed to be ">" or "<" operators
            const compareBy = comparisonOperator === "<" ? "$gt" : "$lt";
            searchObject.$and.push({createdAt: {[compareBy]: ageTimestamp}});
            return undefined;
          }

          if (comparisonOperator === "<") {
            return searchObject.createdAt > ageTimestamp;
          }
          return searchObject.createdAt < ageTimestamp;
        },
      },
      study: {
        criteria: "key",
        columnName: "$enrollment.study.id$",
        throwIfNegated: false,
        isExact: false,
      },
      "study-type": {
        criteria: "key",
        columnName: "$enrollment.study.studyType$",
        throwIfNegated: false,
        isExact: false,
      },
      classification: {
        criteria: "key",
        columnName: "$ecgEvent.userClassification$",
        throwIfNegated: false,
        isExact: false,
      },
      facility: {
        criteria: "key",
        columnName: "$enrollment.study.facility.name$",
        throwIfNegated: false,
        isExact: false,
      },
      recording: {
        criteria: "key",
        columnName: "$enrollment.deviceEnrollmentId$",
        throwIfNegated: false,
        isExact: false,
      },
      before: {
        criteria: "timestamp",
        period: "before",
        columnName: "timestamp",
        throwIfNegated: true,
      },
      after: {
        criteria: "timestamp",
        period: "after",
        columnName: "timestamp",
        throwIfNegated: true,
      },
    };

    this.myItemsSearchParams = this._InboxItemSearchParamsService.instantiate("myItems");
    this.itemsNotAssignedToMeSearchParams =
      this._InboxItemSearchParamsService.instantiate("itemsNotAssignedToMe");
    this.completedItemsSearchParams = this._InboxItemSearchParamsService.instantiate("completedItems");

    if (!this.features.itemAssignments) {
      delete this.myItemsSearchParams?.assignedToMe;
      delete this.itemsNotAssignedToMeSearchParams?.assignedToMe;
    }

    this.nextAvailableItem = "";

    this.prefilledSearch = "";
    const searchFromStorage = this._$window.localStorage.getItem("search");
    if (searchFromStorage) {
      const currentTime = new Date().getTime();
      const search = JSON.parse(searchFromStorage);

      if (currentTime < search.timeExpired) {
        this.prefilledSearch = search.text;
      }
      this._$window.localStorage.removeItem("search");
    }

    this.$onInit = this._init;
  }

  /// Public Functions ///
  get selectedItem() {
    return this._InboxItemService.selectedItem;
  }

  async displayNextAvailableItem() {
    this.loadingNextAvailable = true;

    try {
      await this._InboxItemService.deselectItem();

      // get the item to display on the page
      const assignedToMeParams = this._InboxItemSearchParamsService.instantiate("nextAvailableMyItems");
      assignedToMeParams.order = this.myItemsSearchParams.order;
      if (!this.features.itemAssignments) {
        delete assignedToMeParams.assignedToMe;
      }
      let {item, error} = await this._getNextAvailableAndCatch(assignedToMeParams);

      // if item assignments are not enabled, don't attempt to get next item a second time
      if (!item && this.features.itemAssignments) {
        const notAssignedToMeParams = this._InboxItemSearchParamsService.instantiate(
          "nextAvailableNotAssignedToMe"
        );
        notAssignedToMeParams.order = this.itemsNotAssignedToMeSearchParams.order;
        ({item, error} = await this._getNextAvailableAndCatch(notAssignedToMeParams));
      }
      if (error) {
        throw error;
      }

      // pretend we clicked the item
      // this will lock it with our session id and fetch the needed ecg information
      this._ScrollService.scrollToElement("reviewNextAvailableButton");

      // Valid timestamps are 2010 or later
      item.validStudyStartDate = DateTime.fromISO(item.studyStartDate).year >= 2010;

      if (item.validStudyStartDate) {
        item.displayedStudyStartDate = DateTime.fromJSDate(new Date(item.studyStartDate)).toFormat(
          "yyyy-MM-dd HH:mm"
        );
      } else {
        item.displayedStudyStartDate = "Unknown Timestamp";
      }

      item.isNextAvailable = true;
      this.nextAvailableItem = item;
      Object.assign(item, await this._InboxItemService.selectItem(item, false));

      this._$scope.$apply();
      setTimeout(() => this._ScrollService.scrollToElement(item.id), 100);

      this.loadingNextAvailable = false;
    } catch (err) {
      this.loadingNextAvailable = false;
      this._$scope.$apply();

      let popupTitle = "Failed to find item";
      let errorMessage = `Unable to find an inbox item available for review. Status Code: ${err.status}`;

      if (err.status === -1) {
        popupTitle = "Network Error";
        errorMessage = "There was a network error, please try again.";
      } else if (err.status === 404) {
        errorMessage = "Unable to find an inbox item available for review.";
      } else if (err.status === 403) {
        popupTitle = "Viewing maximum allowed items";
        errorMessage =
          "You have too many items open. Please close other open items in order to view this item. " +
          "If you think this is a mistake, please log out and log back in.";
        // If the error was more than an item simply not being found or exceeding locks, log it
      } else if (err.message?.startsWith("Search text invalid: ")) {
        popupTitle = "Search text invalid";
        errorMessage = err.message.substr(21);
      } else {
        console.error(err);

        // If the error has a message, use that message
        if (err?.data?.detail?.message) {
          errorMessage = err.data.detail.message.replace(/\n/g, "<br />");
        }
      }

      this._$mdDialog.show(
        this._$mdDialog
          .alert()
          .title(popupTitle)
          .htmlContent(
            `<p class="warningMessage"><i class="material-icons dialogErrorIcon"> error </i> ` +
              `${errorMessage}</p>`
          )
          .ok("Ok")
      );
    }
  }

  /**
   * Displays and handles the new study dialog
   *
   * @param {object} event The click event that triggered the opening of the dialog
   * @returns {Promise}
   *
   * @memberOf module:app/inbox.inboxController
   * @see SRS: BR-2138
   */
  clickedNewStudy(event) {
    return this._showNewStudyDialog(event)
      .then(() => {})
      .catch(() => {
        /* do nothing on dialog cancel */
      });
  }

  /// Private Functions ///

  async _init() {
    await this._InboxItemService.deselectItem();
    await this._unlockAllItemsOnRefresh();
  }

  async _unlockAllItemsOnRefresh() {
    if (this._Session.pageWasRefreshed()) {
      const jwt = this._Authentication.getJwt();
      const jwtPayload = this._Authentication.getJwtPayload(jwt);
      await this._InboxItemService.unlockAllItemsForSession(jwtPayload.user, this._Session.sessionId);
      this._Session.generateNewSessionId();
    }
  }

  _getNextAvailableAndCatch(params) {
    return this._InboxItemService
      .getNextInboxItemForReview(params)
      .then((item) => ({item}))
      .catch((error) => ({error}));
  }
}
